import { Theme } from "@emotion/react";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

export interface Config {
  turnstile_key: string;
  line_oa_url: string;
  logo: string;
  is_pro_pubtag: boolean;
  is_pro_no_pubtag: boolean;
  is_pro_general: boolean;
  is_wheel_open: boolean;
  is_verify_bank: boolean;
  wheel_max_deposit: string;
  wheel_buy_credit: string;
  is_mystery_box_open: boolean;
  mystery_box_max_winloss: string;
  admin_domain: string;
}

type State = {
  config: Config | null;
  theme: Object | {};
};

type Actions = {
  setConfig: (config: Config) => void;
  setTheme: (theme: Object) => void;
};

export const useConfigStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      config: null,
      theme: {},
      setConfig: (config) => set(() => ({ config })),
      setTheme: (theme) => set(() => ({ theme })),
    }))
  ),
  Object.is
);
